<template>
  <div>
    <div class="header">
      <img class="bg" src="/img/newsBg.png" alt="" />
      <div class="content">
        <div>
          <div class="title">新闻动态</div>
          <div class="desc">用科技创造未来 /<br />循环和再生，让地球更年轻</div>
        </div>
      </div>
    </div>
    <template v-if="list.length >= 4">
      <div class="first">
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div class="card-1" @click="showDetail(list[0].id)">
              <img :src="list[0].img" alt="" />
              <p v-html="list[0].title"></p>
              <span>{{ list[0].time }}
                  <br>
                  <img src="/img/leftJt.png" style="width:9rem" alt="">
              </span>
              

              
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div class="title">最新动态</div>
            <div class="card-2" @click="showDetail(list[1].id)">
              <img :src="list[1].img" alt="" />
              <p v-html="list[1].title"></p>
              <span>{{ list[1].time }}</span>
            </div>
            <div class="card-2" @click="showDetail(list[2].id)">
              <img :src="list[2].img" alt="" />
              <p v-html="list[2].title"></p>
              <span>{{ list[2].time }}</span>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="card-3" @click="showDetail(list[3].id)">
        <img :src="list[3].img" alt="" />
        <div class="content">
          <p v-html="list[3].title"></p>
          <span>{{ list[3].time }}</span>
        </div>
      </div>

      <div class="third">
        <div>
          <el-row>
              <el-col
                :xs="24"
                :sm="24"
                :md="12"
                :lg="12"
                :xl="12"
                v-for="it in otherList"
                :key="it.id"
              >
                <div class="card-4" @click="showDetail(it.id)">
                  <div class="imgBox">
                      <img :src="it.img" alt="" />
                  </div>
                  <p v-html="it.title"></p>
                  <span>{{ it.time }}</span>
                </div>
              </el-col>
          </el-row>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { getNews } from "@/api.js";
export default {
  data() {
    return {
      list: []
    };
  },
  computed: {
    otherList() {
      return this.list.slice(4);
    }
  },
  async mounted() {
    let res = await getNews();
    this.list = res.data;
  },
  methods: {
    showDetail(id) {
      let t = this.list.find(it => it.id == id);
      this.$router.push({
        name: "newsDetail",
        params: t
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "../header.scss";
.first {
  background-color: #000922;
  .card-1 {
    cursor: pointer;
    img {
      width: 100%;
    }
    p {
      font-size: 4rem;
      line-height: 1.2;
      letter-spacing: 0.9375px;
      color: #ffffff;
      margin: 4.3rem 0 3.3rem;
      padding-left: 10vw;
      padding-right: 20px;
      box-sizing: border-box;
      font-weight: 200;
    }
    span {
      font-size: 2rem;
      line-height: 2;
      letter-spacing: 1px;
      color: #ffffff;
      padding-left: 10vw;
      padding-right: 20px;
      box-sizing: border-box;
      display: block;
      width: 100%;
      font-weight: 200;
    }
  }
  .title {
    width: 80%;
    max-width: 650px;
    margin: 0 auto;
    font-size: 4rem;
    line-height: 1.2;
    letter-spacing: 1px;
    color: #ffffff;
    padding: 7.2rem 0 5.4rem;
    font-weight: 200;
  }
  .card-2 {
    cursor: pointer;
    width: 80%;
    max-width: 650px;
    margin: 0 auto;
    img {
      width: 100%;
    }
    p {
      font-size: 3rem;
      line-height: 1.2;
      letter-spacing: 0.9375px;
      color: #ffffff;
      margin: 1.7rem 0;
      font-weight: 200;
    }
    span {
      font-size: 2rem;
      line-height: 2;
      letter-spacing: 1px;
      color: #ffffff;
      margin-bottom: 8rem;
      display: block;
    }
  }
}

.card-3 {
  cursor: pointer;
  img {
    width: 100%;
  }
  position: relative;
  .content {
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 9, 34, 0.0001) -2.08%,
      rgba(0, 9, 34, 0.510883) 35.69%,
      #000922 100%
    );
    padding: 19rem 20rem;
    width: 100%;
    box-sizing: border-box;
    p {
      font-size: 7rem;
      line-height: 1.2;
      letter-spacing: 1px;
      color: #ffffff;
      margin-bottom: 4rem;
      font-weight: 200;
    }
    span {
      font-size: 3rem;
      line-height: 1.2;
      letter-spacing: 0.75px;
      color: #ffffff;
      font-weight: 200;
    }
  }
}
.third {
  & > div {
    width: 100%;
    max-width: 1520px;
    margin: 0 auto;
    .card-4 {
      cursor: pointer;
      background-color: #f4f4f4;
      width: 80%;
      margin: 9rem auto;
      .imgBox{
        width: 100%;
        padding-top: 62.5%;
        position: relative;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      p {
        height: 8rem;
        width: 100%;
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 3.2rem;
        line-height: 1.2;
        letter-spacing: 1px;
        color: #000;
        margin: 4rem 0;
        padding: 0 4rem;
        font-weight: 200;
        word-break: break-all;
      }

      span {
        font-size: 2rem;
        line-height: 1.2;
        letter-spacing: 0.75px;
        color: #9a9a9a;
        display: block;
        padding: 0 4rem 3.2rem;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .card-3 .content {
    padding: 5rem 5rem;
    p {
      font-size: 3rem;
    }
    span {
      font-size: 2rem;
    }
  }
}
</style>
